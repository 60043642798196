export const CalculateAge = {
    methods: {
        calculateAge(dob) {
            let currentDate = new Date();
            let birthDate = new Date(dob);
            let age = currentDate.getFullYear() - birthDate.getFullYear();
            let month = currentDate.getMonth() - birthDate.getMonth();
            if (
                month < 0 ||
                (month === 0 && currentDate.getDate() < birthDate.getDate())
            ) {
                age--;
            }
            return age;
        },

    },
};
